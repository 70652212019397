<template>
  <component :is="tag"  v-if="blok.informations.length > 0" class="contact-informations parent-bg">
    <div class="section-inner">
      <div v-if="title || blok.text" class="heading margin-s-bottom">
        <h2 v-if="title" class="size-m bold" v-html="title"></h2>
        <p v-if="blok.text" class="size-s text">{{ blok.text }}</p>
      </div>
      <ul v-if="blok.informations.length > 0" class="infos-list">
        <li
          v-for="item in blok.informations"
          :key="item"
          :class="`infos-item${item === 'rte' ? ' special' : ''}`"
        >
        <SimpleButton
          v-if="contactInfo(item)?.condition"
          :href="contactInfo(item)?.href"
          :to="contactInfo(item)?.to"
          :link="contactInfo(item)?.link"
          :target="contactInfo(item)?.target"
          class="infos-block"
          @click="contactInfo(item)?.handleClick"
        >
          <template v-if="contactInfo(item)?.html" >
            <span v-html="contactInfo(item)?.html"></span>
          </template>
          <template v-else>
            <SvgIcon :name="contactInfo(item)?.svgName" />
            <span class="margin-xs-top size-s text">
              {{ contactInfo(item)?.label}}
            </span>
          </template>
        </SimpleButton>
        </li>
      </ul>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  context: {
    type: [Object, undefined],
    required: false,
    default: undefined,
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});
const trackingStore = useTrackingStore();
const { blok, context } = toRefs(props); // eslint-disable-line
const freeText = useRenderRichText(blok.value.free_content);
const mergedData = computed(() => ({ ...context.value, ...blok.value }));
const title = blok.value.title.replace(/\n/g, "<br />");

const phoneNumber = blok.value.phone_number?.length ? blok.value.phone_number : mergedData.value.phoneNumber
const phonePrefix = blok.value.phone_prefix?.length ? blok.value.phone_prefix : mergedData.value.prefix


let facilityTypeGroupKey;
let callCenterPhoneNumber;
if (mergedData.value.facilityUniqueKey) {
  const query = gql`
    query {
      searchByFacilityUniqueKey(facilityUniqueKey: "${mergedData.value.facilityUniqueKey}") {
        facilityTypeGroupKey
      }
    }
    `;
  const { data } = await useAsyncQuery(query);
  if (typeof data !== "undefined") {
    facilityTypeGroupKey =
      data?.value?.searchByFacilityUniqueKey?.facilityTypeGroupKey;
    callCenterPhoneNumber = usePhoneByFacityType(facilityTypeGroupKey);
  }
}

const formattedPhoneNumber =
  callCenterPhoneNumber && useMicroCopy("phone.prefix")
    ? useFormattedPhone(useMicroCopy("phone.prefix"), callCenterPhoneNumber)
    : useFormattedPhone(phonePrefix ? phonePrefix : useMicroCopy("phone.prefix"), phoneNumber);

const openCallbackModal = inject("callback", () => {});
const openFacilityVisitModal = inject("facility-visit", () => {});

const openVisitModal = () => {
  openFacilityVisitModal();
};

const tracking = (data) => {
  trackingStore.sendTrackingData(data);
};

const clickOnPhone = () => {
  tracking({
    event: 'click.action',
    click: 'call',
    click_chapter1: 'push_contact',
    click_chapter2: 'CTA',
  });
};

const clickOnVisit = () => {
  openVisitModal();
  tracking({
    event: 'click.action',
    click: useSnakeCaseFilter(blok.value.visit_label),
    click_chapter1: 'push_contact',
    click_chapter2: 'CTA',
  });
};

const clickOnBrochure = () => {
  tracking({
    event: 'click.action',
    click: useSnakeCaseFilter(blok.value.brochure_label),
    click_chapter1: 'push_contact',
    click_chapter2: 'CTA',
  });
};

const clickOnCallback = () => {
  openCallbackModal();
  tracking({
    event: 'click.action',
    click: useSnakeCaseFilter(blok.value.callback_label),
    click_chapter1: 'push_contact',
    click_chapter2: 'CTA',
  });
};

const clickOnContact = () => {
  tracking({
    event: 'click.action',
    click: useSnakeCaseFilter(blok.value.contact_label),
    click_chapter1: 'push_contact',
    click_chapter2: 'CTA',
  });
};

const clickOnComplaint = () => {
  tracking({
    event: 'click.action',
    click: useSnakeCaseFilter(blok.value.complaint_label),
    click_chapter1: 'push_contact',
    click_chapter2: 'CTA',
  });
};

const clickOnRte = () => {
  tracking({
    event: 'click.action',
    click: 'looking_for_a_job',
    click_chapter1: 'push_contact',
    click_chapter2: 'CTA',
  });
};


const contactInfo = (item) => {
  let data;

  switch (item) {
    case "phone":
      data = {
        condition: formattedPhoneNumber,
        href: `tel:${formattedPhoneNumber}`,
        target: '_blank',
        handleClick: clickOnPhone,
        svgName: 'func_call',
        label: callCenterPhoneNumber?.length ? callCenterPhoneNumber : phoneNumber,
      };
      break;
    
    case "visit":
      data = {
        condition: blok.value.visit_label,
        handleClick: clickOnVisit,
        svgName: 'func_calendar',
        label: blok.value.visit_label,
      };
      break;

    case "brochure":
      data = {
        condition: blok.value.brochure_label && blok.value.brochure_file.filename,
        href: blok.value.brochure_file.filename,
        target: '_blank',
        handleClick: clickOnBrochure,
        svgName: 'func_brochure',
        label: blok.value.brochure_label,
      };
      break;

    case "callback":
      data = {
        condition: blok.value.callback_label,
        handleClick: clickOnCallback,
        svgName: 'func_incoming-call',
        label: blok.value.callback_label,
      };
      break;

    case "contact":
      data = {
        condition: blok.value.contact_label && blok.value.contact_url,
        to: blok.value.contact_url,
        handleClick: clickOnContact,
        svgName: 'func_mail',
        label: blok.value.contact_label,
      };
      break;

    case "complaint":
      data = {
        condition: blok.value.complaint_label && blok.value.complaint_link,
        link: blok.value.complaint_link,
        handleClick: clickOnComplaint,
        svgName: 'func_question',
        label: blok.value.complaint_label,
      };
      break;

    case "rte":
      data = {
        condition: blok.value.free_content && blok.value.free_url,
        to: blok.value.free_url,
        target: '_blank',
        handleClick: clickOnRte,
        html: freeText,
      };
      break;
    
    default:
      break;
  }

  return data;
};
</script>

<style lang="scss">
.background-contact-informations.alternate {
  @include pair-7(important);
}
</style>

<style lang="scss" scoped>
.contact-informations {
  @include pair-7;

  .text {
    margin-top: 0.5rem;
  }

  .infos-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 25rem;
    margin-left: auto;
    margin-right: auto;
    gap: 0.625rem;
    justify-content: center;

    @include for-tablet-landscape-up {
      max-width: none;
    }
  }

  .infos-item {
    width: calc(50% - 0.315rem);
    max-width: 12.1875rem;
    min-height: 7.625rem;

    @include for-tablet-landscape-up {
      width: 12.6875rem;
      max-width: none;
    }

    @include for-desktop-up {
      width: 15.25rem;
      min-height: 11rem;
    }

    &:last-child:nth-child(odd):not(:first-child) {
      @include for-tablet-landscape-down {
        width: 100%;
        max-width: initial;
      }
    }

    &.special {
      .simple-button {
        @include pair-2;
        justify-content: center;

        & > span {
          white-space: normal;
        }
      }

      :deep(h2) {
        @include size-m;
      }

      :deep(p) {
        @include size-xs;
        font-weight: 500;
      }
    }
  }

  .infos-block {
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem 0.5rem;
    text-align: center;

    &::before {
      background-color: var(--gradient-alt-start);
      background: linear-gradient(
        136deg,
        var(--gradient-alt-start) 0%,
        var(--gradient-alt-end) 100%
      );
    }

    @include for-desktop-up {
      padding: 2.5rem 1.25rem;
    }

    &.simple-button:not(.link) .icon {
      width: 1.5rem;
      height: 1.5rem;

      @include for-desktop-up {
        width: 2rem;
        height: 2rem;
      }
    }

    .text {
      white-space: normal;
    }
  }
}
</style>
